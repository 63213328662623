<template>
  <div class="relative">
    <div 
      class=" text-lg  font-medium menuTitle py-1 px-2 rounded-lg cursor-pointer" 
      :class="`${isMenuOpen ? 'bg-primary2 text-white' : 'text-primary2'}`"
      @click="handleMenuClick"
    >
      {{ title }}
    </div>

    <div v-show="isMenuOpen" class=" absolute mt-2 py-2 px-4 z-40 menuItems minWidth">
      <div class=" flex">
        <div 
          class=" w-full minWidth mr-4 pr-3" 
          :style="{ 'border-right': selectedFirstItem >= 0 ? '1px solid #E5E7EB !important' : '' }"
        >
          <div v-for="(item, index) in items" :key="index" class="relative" @click="handleClickFirstItem(item, index)">
            <div  class="cursor-pointer mb-2 text-primary2 flex justify-between items-center">
              <span class="hover:underline">
                {{ item.name }}
              </span>
              <vs-icon v-if="selectedFirstItem === index" icon="arrow_forward_ios" size="small" class=" cursor-pointer"></vs-icon>
            </div>
          </div>
        </div>
        <div 
          v-if="selectedFirstItem >= 0" 
          class=" mr-4 pr-3"
          :style="{ 'border-right': selectedSecondItem >= 0 ? '1px solid #E5E7EB !important' : '' }"
        >
          <div class=" subItems minWidth transition-opacity duration-500 ease-in-out" :class="{ 'opacity-100': isMenuOpen, 'opacity-0': !isMenuOpen }">
            <div 
              v-for="(subItem, subIndex) in items[selectedFirstItem].categories" 
              :key="subIndex" class="cursor-pointer mb-2 text-primary2 flex justify-between items-center" 
              @click="handleClickSecondItem(subItem, subIndex)"
            >
              <span class="hover:underline">
                {{ subItem.name }}
              </span>
              <vs-icon v-if="selectedSecondItem === subIndex" icon="arrow_forward_ios" size="small" class=" cursor-pointer"></vs-icon>
            </div>
            <div :key="items[selectedFirstItem].categories.length" class="cursor-pointer mb-2 text-primary2 hover:underline" @click="handleSubItemClick({ slug: items[selectedFirstItem].slug })">
              {{ this.$isAuRegion() ? 'ALL' : 'SHOP ALL' }} {{ items[selectedFirstItem].name.toUpperCase() }}
            </div>
          </div>
        </div>
        <div v-if="selectedSecondItem >= 0" 
          class=" mr-4 pr-3"
          :style="{ 'border-right': selectedThirdItem >= 0 ? '1px solid #E5E7EB !important' : '' }"
        >
          <div class=" subItems minWidth transition-opacity duration-500 ease-in-out" :class="{ 'opacity-100': isMenuOpen, 'opacity-0': !isMenuOpen }">
            <div v-for="(subItem, subIndex) in items[selectedFirstItem].categories[selectedSecondItem].categories" :key="subIndex" class="cursor-pointer mb-2 text-primary2 flex justify-between items-center" @click="handleClickThirdItem(subItem, subIndex)">
              
              <span class="hover:underline">
                {{ subItem.name }}
              </span>
              <vs-icon v-if="selectedThirdItem === subIndex" icon="arrow_forward_ios" size="small" class=" cursor-pointer"></vs-icon>
            </div>
            <div :key="items[selectedFirstItem].categories.length" class="cursor-pointer mb-2 text-primary2 hover:underline" @click="handleSubItemClick({ slug: items[selectedFirstItem].categories[selectedSecondItem].slug })">
              SHOP ALL {{ items[selectedFirstItem].categories[selectedSecondItem].name.toUpperCase() }}
            </div>
          </div>
        </div>
        <div v-if="selectedThirdItem >= 0" >
          <div class=" subItems minWidth transition-opacity duration-500 ease-in-out" :class="{ 'opacity-100': isMenuOpen, 'opacity-0': !isMenuOpen }">
            <div v-for="(subItem, subIndex) in items[selectedFirstItem].categories[selectedSecondItem].categories[selectedThirdItem].categories" :key="subIndex" class="cursor-pointer mb-2 text-primary2 hover:underline" @click="handleClickThirdItem(subItem, subIndex)">
              {{ subItem.name }}
            </div>
            <div :key="items[selectedFirstItem].categories.length" class="cursor-pointer mb-2 text-primary2 hover:underline" @click="handleSubItemClick({ slug: items[selectedFirstItem].categories[selectedSecondItem].slug })">
              SHOP ALL {{ items[selectedFirstItem].categories[selectedSecondItem].categories[selectedThirdItem].name.toUpperCase() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    items: Array,
    isMenuOpen: Boolean
  },
  data() {
    return {
      selectedFirstItem: -1,
      selectedSecondItem: -1,
      selectedThirdItem: -1
    };
  },
  methods: {
    handleMenuClick(){
      this.selectedFirstItem = -1
      this.selectedSecondItem = -1
      this.$emit('menuClick');
    },
    handleClickFirstItem(item, index) {
      if(item.categories.length > 0 ){
        //has subcategories
        this.selectedThirdItem = -1
        if(this.selectedFirstItem === index){
          this.selectedFirstItem = -1
          this.selectedSecondItem = -1
        }
        else {
          this.selectedSecondItem = -1
          this.selectedFirstItem = index
        }
      }
      else {
        this.handleSubItemClick(item)
      }
    },
    handleClickSecondItem(item, index){
      if(item.categories.length > 0 ){
        this.selectedThirdItem = -1
        if(this.selectedSecondItem === index){
          this.selectedSecondItem = -1
        }
        else {
          this.selectedSecondItem = index
        }
      }
      else {
        this.handleSubItemClick(item, index)
      }
    },
    handleClickThirdItem(item, index){
      if(item.categories.length > 0 ){
        if(this.selectedThirdItem === index){
          this.selectedThirdItem = -1
        }
        else {
          this.selectedThirdItem = index
        }
      }
      else {
        this.handleSubItemClick(item, index)
      }
    },
    handleSubItemClick(subItem) {
      this.selectedFirstItem=-1
      this.selectedSecondItem=-1
      this.selectedThirdItem=-1
      this.$emit('itemClick', subItem.slug);
    },
  },
  watch: {
    "isMenuOpen": function(newVal, old){
      if(newVal){
        this.selectedFirstItem=-1
      }
    }
  }
};
</script>

<style scoped>

.subItems{
  /* left: 248px; */
  /* border: 1px solid #E5E7EB !important; */
  /* background-color: white; */
  /* opacity: 0; */
  width: max-content;
  /* top: -8px; */
}
.minWidth {
  min-width: 264px;
}
.menuItems {
  border: 1px solid #E5E7EB !important;
  background-color: white;
}

.menuTitle:hover {
  background-color: #F3FAF8;
}

</style>
