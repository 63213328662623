<template>
  <div >
    <!-- desktop -->
    <div class="hidden sm:block relative bg-green-lightest rounded-full w-full py-1">
      <input @click="toggleSearchModal(true)" type="text" placeholder="Search for Fillers, Brands, etc"
          class="border-0 w-full px-6 py-2 bg-transparent text-primary2 placeholder-gray-500 searchInput" v-model="searchText"
          @input="querySearch" />
      <vs-icon icon="search" size="small" class="absolute right-0 top-0 mr-1 -translate-y-1/2 rounded-full w-10 h-10 flex items-center justify-center"></vs-icon>
    </div>
    <!-- mobile -->
      <vs-icon @click="toggleSearchModal(true)" icon="search" size="22px" bg="#DEEFEA" round class="sm:hidden p-5 flex items-center justify-center cursor-pointer"></vs-icon>
    <div v-if="this.$store.state.eCommerce.showSearchModal"
      class="fixed block md:hidden overflow-auto left-0 top-0 z-50 h-full w-full bg-white">

      <div class="flex w-full bg-primary2 py-2 px-2">
        <div class="bg-white border-4 items-center flex w-full rounded-lg px-4">
          <vs-icon icon="search" class="text-gray-500 text-2xl mr-2"> </vs-icon>
          <input class="border-none rounded-2xl w-full text-base searchInput" style="height: 36px;" @input="debouncedSearch" v-model="query"
            ref="mobileSearchText" placeholder="Search for Fillers, Brands, etc" />
        </div>
        <button @click="toggleSearchModal(false)" class="pl-4 pr-2 border-none text-white bg-transparent searchInput">
          Cancel
        </button>
      </div>

      <div v-if="!!this.query" class="p-4">

        <!-- Brands -->
        <div v-if="this.brandsResults.length">
          <h3 class="font-semibold mb-3" style="font-size: 14px;">
            Brands
          </h3>
          <div v-for="result in brandsResults" :key="result.objectID" class="cursor-pointer mb-3 result-list">
            <!-- <img :src="result.image" style="width: 50px;" /> -->
            <span @click="onResultClick(`brands/${result.slug}?page=1`)">{{ result.name }}</span>
          </div>

        </div>

        <!-- Brands -->
        <div v-if="this.categoriesResults.length">
          <h3 class="font-semibold mb-3" style="font-size: 14px;">
            Categories
          </h3>
          <div v-for="result in categoriesResults" :key="result.objectID" class="cursor-pointer mb-3 result-list">
            <!-- <img :src="result.image" style="width: 50px;" /> -->
            <span @click="onResultClick(`category/${result.slug}?page=1`)">{{ result.name }}</span>
          </div>
        </div>

        <!-- Products -->
        <div>
          <h3 class="font-semibold mb-3" style="font-size: 14px;">
            Products
          </h3>

          <div class="flex w-full py-4 px-1 overflow-auto gap-5">

            <div v-for="result in productResults" :key="result.objectID" @click="onResultClick(result.objectID, true)"
              class="cursor-pointer hover:shadow-lg result-products-mobile rounded-lg shadow flex flex-col">
              <img class="w-full object-cover" :src="result.image" alt="Product Image" />
              <div class="result-products-mobile-body text-primary2 p-5 py-4 flex flex-col justify-between flex-grow"
                style="font-size:12px;">
                <div
                  class="text-primary2"
                  v-if="result.brand && isBrandVisible(result.brand.brandName)">
                  {{ result.brand.brandName }}
                </div>
                <span class="max-h-2 ellipsis">{{ productName(result) }}</span>
                <!-- <p class="text-primary2 font-normal" style="font-size:14px">
                  {{ result.description }}
                </p> -->
                <div class="font-bold text-primary2" style="font-size:12px; ">
                  <!-- ${{ price(result) | formatDecimals }} -->
                  ${{ hasDiscount(result._id) ? hasDiscount(result._id) : price(result) | formatDecimals }}
                </div>
                <!-- price per measurement -->
                <div
                  class="text-primary2 mt-2 text-xs"
                  v-if="result.isPricePerMeasurementHidden"
                >
                  ${{ measurementPrice(result) }}
                </div>
                <!-- end of price per measurement -->
                <!-- RRP -->
                <div
                  class="text-primary2 mt-1 text-xs"
                  v-if="result.isRRPHidden !== undefined && !result.isRRPHidden"
                  >
                  RRP <span :class="result.isCompareAtPriceToggled && 'line-through'">${{ result.compareAtPrice | formatDecimals }}</span>
                </div>
                <!-- End of RRP -->
                <!-- List Price -->
                <div
                  class="text-primary2 mt-1 text-xs"
                  v-if="result.isListPriceHidden !== undefined && !result.isListPriceHidden"
                  >
                  List Price <span :class="result.isListPriceToggled && 'line-through'">${{ result.listPrice | formatDecimals }}</span>
                </div>
                <!-- End of List Price -->
              </div>
            </div>

          </div>
        </div>

        <div
          class="cursor-pointer w-full mt-8 font-medium flex border border-solid text-gray-900 rounded-lg border-gray-300 justify-center items-center"
          style="height:40px;" @click="onViewAllSearch">
          View All Results
        </div>
      </div>
    </div>

    <div v-if="this.$store.state.eCommerce.showSearchModal"
      class="fixed hidden  md:block search-modal overflow-auto p-5 right-0 top-0 z-50 h-full w-full"
      style="background-color: rgba(0,0,0,.1)">
      <div class="w-full transition-all duration-700 p-4 flex justify-center bg-white gap-2 rounded-lg "
        style="height:66px;">

        <span ref="desktopCloseButton" @click="toggleSearchModal(false)" class="cursor-pointer material-icons text-primary2 mt-1 h-3 "
          style="font-size: 28px">
          close
        </span>

        <div class=" border-solid justify-center items-center flex rounded-lg "
          style="height: 38px; width: 595px; border: 1px solid #E3EAE8;">
          <div class="h-full flex items-center justify-center w-10">
            <span class="material-icons text-primary2" style="font-size: 28px">
              search
            </span>
          </div>

          <input class="border-none rounded-lg w-full" style="height: 32px;" v-model="query" @input="debouncedSearch"
            ref="desktopSearchText" placeholder="Search for Fillers, Brands, etc" />
        </div>


        <div v-if="!!this.query" class="absolute p-4 bg-white border border-solid rounded-lg ml-10 dropdown-results"
          style=" width: 595px; border-color: #ccc; margin-top: 46px;">

          <!-- Brands -->
          <div v-if="this.brandsResults.length">
            <h3 class="font-semibold mb-3" style="font-size: 14px;">
              Brands
            </h3>
            <div v-for="result in brandsResults" :key="result.objectID" class="cursor-pointer mb-3 result-list">
              <!-- <img :src="result.image" style="width: 50px;" /> -->
              <span @click="onResultClick(`brands/${result.slug}?page=1`)">{{ result.name }}</span>
            </div>
          </div>

          <!-- Categories -->
          <div v-if="this.categoriesResults.length">
            <h3 class="font-semibold mb-3" style="font-size: 14px;">
              Categories
            </h3>
            <div v-for="result in categoriesResults" :key="result.objectID" class="cursor-pointer mb-3 result-list">
              <!-- <img :src="result.image" style="width: 50px;" /> -->
              <span @click="onResultClick(`category/${result.slug}?page=1`)">{{ result.name }}</span>
            </div>
          </div>

          <!-- Products -->
          <div>
            <h3 class="font-semibold mb-3" style="font-size: 14px;">
              Products
            </h3>

            <div class="flex w-full py-4 px-1 overflow-auto gap-2">

              <div v-for="result in productResults" :key="result.objectID" @click="onResultClick(result.objectID, true)"
                class="cursor-pointer hover:shadow-lg result-products rounded-lg shadow flex flex-col">
                <img class="w-full object-cover" :src="result.image" alt="Product Image" />

                <div
                  class="result-products-body p-5 py-4 flex-grow flex flex-col justify-between"
                  :class="{ 'py-4': !result.isPricePerMeasurementHidden, 'py-1': !!result.isPricePerMeasurementHidden }"
                  style="font-size:12px;">
                  <div
                    class="text-primary2"
                    v-if="result.brand && isBrandVisible(result.brand.brandName)"
                  >
                    {{ result.brand.brandName }} </div>
                  <span style="text-overflow: ellipsis !important; height:36px; -webkit-line-clamp: 2;"
                    class="ellipsis overflow-hidden">{{ productName(result) }}</span>
                  <!-- <p class="text-primary2 font-normal" style="font-size:14px">
                  {{ result.description }}
                  </p> -->
                  <div class="font-bold text-primary2" style=" font-size:12px">
                    <!-- ${{ price(result)  | formatDecimals }} -->
                    <!-- ${{ hasDiscount(result._id) ? hasDiscount(result._id) : price(result) | formatDecimals }} -->
                    ${{ hasDiscount(result._id) ? hasDiscount(result._id) : result.price | formatDecimals }}
                  </div>
                  <!-- price per measurement -->
                  <div
                    class="text-primary2 mt-2 text-xs"
                    v-if="result.isPricePerMeasurementHidden"
                  >
                    ${{ measurementPrice(result) }}
                  </div>
                  <!-- end of price per measurement -->
                  <!-- RRP -->
                  <div
                    class="text-primary2 mt-1 text-xs"
                    v-if="result.isRRPHidden !== undefined && !result.isRRPHidden"
                  >
                    RRP <span :class="result.isCompareAtPriceToggled && 'line-through'">${{ result.compareAtPrice | formatDecimals }}</span>
                  </div>
                  <!-- End of RRP -->
                  <!-- List Price -->
                  <div
                    class="text-primary2 m1-1 text-xs"
                    v-if="result.isListPriceHidden !== undefined && !result.isListPriceHidden"
                  >
                    List Price <span :class="result.isListPriceToggled && 'line-through'">${{ result.listPrice | formatDecimals }}</span>
                  </div>
                  <!-- End of List Price -->
                </div>
              </div>

            </div>
          </div>

          <div
            ref="viewAllButton"
            class="cursor-pointer w-full mt-8 font-medium flex border border-solid text-gray-900 rounded-lg border-gray-900 justify-center items-center"
            style="height:40px;" @click="onViewAllSearch">
            View All Results
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapActions, mapMutations } from "vuex";
import algoliasearch from 'algoliasearch/lite';
import StoreHelper from "@/mixins/Store.vue"

/** algolia configuration */
const searchClient = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_ADMIN_);
const product = searchClient.initIndex('products');
const category = searchClient.initIndex('categories');
const brand = searchClient.initIndex('brands');

export default {
  name: "ShopSearchBar",
  props: [],
  mixins: [StoreHelper],
  data() {
    return {
      query: '',
      productResults: [],
      categoriesResults: [],
      brandsResults: [],
      userTypeAdmin: undefined,
      discounts: [],
      clinic: null
    };
  },
  computed: {
    searchText: {
      get() {
        return this.$store.state.eCommerce.searchText;
      },
      set: debounce(function (val) {
        this.$store.dispatch("eCommerce/updateSearchText", val);
      }, 800)
    }
  },
  components: {},
  methods: {
    ...mapMutations("eCommerce", ["UPDATE_SHOW_SELECT_CLINIC_MODAL"]),
    ...mapActions("ecommerce", ["updateSearchText"]),
    ...mapActions("discount", ["fetchDiscountList"]),
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    price(result){
      if(result.discounts.length > 0){
        const clinic = sessionStorage.getItem(`doctorClinicSelectedClinic`);
        const price = result.discounts.filter((discount) => discount.clinicId === clinic)
        if(price.length > 0){ 
          return price[0].adjustedPrice
        }
      }
      if(this.userTypeAdmin === "doctor" && result.productPrice) return result.productPrice
      return result.price
    },
    measurementPrice(item) {
      const price = (item.price / item.pricePerMeasurement.price).toFixed(2)
      return `${price} ${item.pricePerMeasurement.unit}`
    },
    querySearch: debounce(function (event) {
      const { value } = event.target;

      const userType = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;

      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );


      let routerName = "";

      if(this.userTypeAdmin && this.userTypeAdmin === "superAdmin") {
        routerName = "SuperAdminOrderCenterSearch"

      } else {
        if (role === "Org Owner") {
          routerName = "OrgOwnerOrderCenterSearch"
        }
        else {
          if (userType === "doctor") {
            routerName = "DoctorOrderCenteSearch"
          }
          if (userType === "nurse") {
            routerName = "NurseOrderCenterSearch"
          }
          else {
            routerName = "BasicOrderCenterSearch"
          }
        }
      }

      if (this.$route.query.q) {
        return this.$router.replace({ query: { q: value } });
      }
      this.$router.push({ name: routerName, query: { q: value } });

    }, 800),
    toggleSearchModal(val) {
      this.$store.commit("eCommerce/TOGGLE_SHOW_SEARCH_MODAL", val);

      !val && this.resetSearch()

      this.$nextTick(() => {
        this.$refs.desktopSearchText.focus();
        this.$refs.mobileSearchText.focus();
      })

    },
    onResultClick(route, isProduct = false) {
      const isSuperAdmin =
        this.userTypeAdmin && this.userTypeAdmin === "superAdmin";

      // Determine the base URL
      const owner = isSuperAdmin
        ? "super-admin/store"
        : this.$route.path.split("/")[1];
      const shopUrl = `/${owner}/shop`;

      // Construct the URL
      const baseUrl = `/${owner}`;
      const link = isProduct
        ? `${baseUrl}/product/${route}`
        : `${baseUrl}/shop/${route}`;

      /** reset */
      this.resetSearch();

      /** GOTO Result */
      this.$router.replace(link);
      this.$store.commit("eCommerce/TOGGLE_SHOW_SEARCH_MODAL", false);
    },

    resetSearch() {
      this.query = ""
      this.searchText = ""
      this.brandsResults = []
      this.categoriesResults = []
      this.productResults = []
    },
    debouncedSearch: debounce(function () {
      if (this.query === '') {
        this.searchResults = [];
        return;
      }

      const searchPromises = [
        product.search(this.query),
        category.search(this.query),
        brand.search(this.query),
      ];
      Promise.all(searchPromises)
        .then(([productResults, categoriesResults, brandsResults]) => {
          const tempProduct = []
          productResults.hits.map((productDetail)=> {
            if(productDetail.hideStatus ){
                if(productDetail.ifHiddenThenVisibleToClinics){
                  const clinicsHasAccess = productDetail.ifHiddenThenVisibleToClinics.filter((id) => this.clinic.data.data._id === id)
                  if(clinicsHasAccess.length > 0){
                    tempProduct.push(productDetail)
                  }
                }
            }
            else{
              tempProduct.push(productDetail)
            }
          })
          this.productResults = tempProduct;
          this.categoriesResults = categoriesResults.hits;
          this.brandsResults = brandsResults.hits;
        })
        .catch(error => {
          console.error('Algolia search error:', error);
        });
    }, 300),
    onViewAllSearch() {

      /** get base url */
      let owner = this.$route.path.split('/')[1]
      if (this.userTypeAdmin && this.userTypeAdmin === "superAdmin")
        owner = 'super-admin/store'

      const searchUrl = `/${owner}/shop/search`;

      this.$router.push({ path: searchUrl, query: { q: this.query } });
      this.searchText = this.query
      this.toggleSearchModal(false)

    },
    productName(result) {
      if(this.clinic && this.clinic.data.data.isDoctorStore){
        return result.productName
      }
      return result.name
    },


    hasDiscount(productId) {
      const clinicData = this.clinic;
      if (clinicData && clinicData.data.data.clinicDiscounts.length > 0) {
        const discounts = this.discounts
          .filter((value) =>
            clinicData.data.data.clinicDiscounts.includes(value._id)
          )
          .map((d) => d.productDiscounts)
          .flat()
          .filter(
            (productDiscount) =>
              productDiscount.productStoreId === productId &&
              productDiscount.isActive
          );
        const applyDiscount = _.minBy(discounts, "adjustedPrice");
        if (applyDiscount) {
          return applyDiscount.adjustedPrice;
        }
      }
      return null;
    },

    async fetchDiscounts() {
      await this.fetchDiscountList().then((response) => {
        this.discounts = response.data.data;
      });
    },
    async fetchClinic() {
      this.clinic = await this.fetchClinicsDetail(sessionStorage.getItem("doctorClinicSelectedClinic"));
    }
  },
  async created() {
    this.fetchDiscounts()
    this.fetchClinic()

    if (this.$route.query.q) {
      this.$store.dispatch("eCommerce/updateSearchText", "");
    }
    
    document.addEventListener("keyup", (event) => {
      if (event.key === "Escape" && this.$refs.desktopCloseButton) {
        this.$refs.desktopCloseButton.click();
      }
      if (event.key === "Enter" && this.$refs.viewAllButton) {
        this.$refs.viewAllButton.click();
      }
    });

    this.userTypeAdmin = this.$store.state.AppActiveUser.userType
  },
  watch: {
    "$store.state.clinicId": function (val) {
        this.fetchDiscounts()
        this.fetchClinic()
      },
  }
};
</script>

<style scoped lang="scss">
.searchInput {
  font-family: inherit;
  font-size: 16px !important;
}

.result-list {
  color: #999;
}

.result-products {
  width: 181px;
  min-width: 181px;
}


.result-products>img {
  height: 125px;
}

.result-products-mobile {
  width: 150px;
  min-width: 150px;
  height: 302px;
}

.result-products-mobile-body {
  height: 85px;
}

.result-products-mobile>img {
  height: 125px;
}

.search-modal {
  @media (min-width: 1200px) {
    width: calc(100vw - 280px - var(--scrollbar-width)) !important;
  }
}

.ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  white-space: pre-wrap;
}</style>
